import React, {useState, useEffect} from "react";
import { database } from "../firebase";
import { useParams,Link } from 'react-router-dom';
import {formatTime, humanFileSize} from "./All";
import { getVideoEntry } from '../helper'

// import { Player, ControlBar, PlaybackRateMenuButton } from 'video-react';

const Video = () => {
    const { user, fileName } = useParams();
    const [video, setVideo] = useState()

    const deleteVideo = () => {
        database.ref('users/' + user).on('value', (snapshot) => {
            const userData = snapshot.val()
            if(userData) {
                const videoId = fileName.replace('.mp4', '')
                database.ref('users/' + user +'/videoList/' + videoId + '/status')
                    .set('deleted')

                let videos = []
                for (const [key,value] of Object.entries(userData.videoList)) {
                    videos.push(value)
                }

                let validVideos = videos.sort(function(a, b) {
                    return b.date - a.date;
                }).filter(v => v.status === '');
                if(validVideos && validVideos.length > 0) {
                    database.ref('users/' + user +'/lastRecording').set(validVideos[0].date)
                }
                window.location = "/user/" + user
            }
        });
    }

    useEffect(() => {
        async function fetchData() {
            const videoId = fileName.replace('.mp4', '')
            let videoRef = await database.ref('users/' + user +'/videoList/' + videoId);
            videoRef.on('value', (snapshot) => {
                const videoSnap = snapshot.val()
                if(videoSnap) {
                    setVideo(videoSnap)
                }
            });

        }
        fetchData();
    }, [user, fileName]);

    let videoEntry = undefined
    if(video) {
        videoEntry = getVideoEntry(video, user)
    }

    return <div>
        <div className="mgt-5 mgb-5">
            <Link to="/all" className="btn btn-light mgr-10 ">All</Link>
            <Link to={`/user/${user}`} className="btn btn-light mgr-10 ">Back</Link>
            <a href={`/manage/${user}`} className="btn btn-light mgr-10 float-right">manage</a>
        </div>
        <hr/>
        { video ?
             <div key={video.filename}>
                 <a href={videoEntry.url} target="_blank" rel="noreferrer">{video.filename}</a>
                 <br/>
                 {video.metadata ?
                     <span>{formatTime(video.metadata.duration)} ({humanFileSize(video.metadata.size)})<br/></span>
                     : ''
                 }
                 <br/>
                <button className="btn btn-secondary" onClick={() => {deleteVideo()}}>Delete</button>
                 <br/>
                 <br/>
                { videoEntry.videoUrl.includes("microsoft") && 
                    <div> MS
                    <video src={videoEntry.videoUrl} controls poster={videoEntry.imageUrls.imageSmall}/>
                    </div>
                }
                { videoEntry.videoUrl.includes("google") && 
                    <div> GD
                    <iframe src={videoEntry.videoUrl} style={{maxWidth: "100%", minWidth: "640px"}} height="480" allowFullScreen="true"></iframe>
                    </div>
                }
                 
                
                 {/* <VideoPlayer
                     controls={true}
                     src={videoEntry.videoUrl}
                     poster={videoEntry.imageUrls.imageSmall}
                     playbackRates={[0.5, 1, 2, 5, 7, 10, 15]}
                 /> 
                  <ReactPlayer
                     url={videoEntry.videoUrl}
                     controls={true}
                 />  */}
                 {/* <Player
                    playsInline
                    poster={videoEntry.imageUrls.imageSmall}
                >
                    <source
                    src={videoEntry.videoUrl}
                    type="video/mp4"
                    />
                    <ControlBar>
                    <PlaybackRateMenuButton rates={[0.5, 1, 2, 5, 7, 10, 15]} />
                    </ControlBar>
                </Player> */}
                 <br/>
                 { !videoEntry.imageUrls.one.includes("microsoft") && 
                    <a href={`/image/${videoEntry.imageUrls.oneId}`} target="_blank" rel="noreferrer">
                        <img alt="" src={videoEntry.imageUrls.oneSmall} className="preview-image" />
                    </a>
                 }
                 { videoEntry.imageUrls.one.includes("microsoft") && 
                    <a href={videoEntry.imageUrls.one} target="_blank" rel="noreferrer">
                        <img alt="" src={videoEntry.imageUrls.oneSmall} className="preview-image" />
                    </a>
                 }
                 { !videoEntry.imageUrls.one.includes("microsoft") && 
                    <a href={`/image/${videoEntry.imageUrls.oneId}`} target="_blank" rel="noreferrer">
                        <img alt="" src={videoEntry.imageUrls.oneSmall} className="preview-image" />
                    </a>
                 }
                 <br/>
                 { videoEntry.imageUrls.image.includes("microsoft") && 
                 <a href={videoEntry.imageUrls.image} target="_blank" rel="noreferrer">
                     <img alt="" src={videoEntry.imageUrls.imageSmall} className="preview-image" />
                 </a>
                 }
                 { !videoEntry.imageUrls.image.includes("microsoft") && 
                 <a href={`/image/${videoEntry.imageUrls.imageId}`} target="_blank" rel="noreferrer">
                     <img alt="" src={videoEntry.imageUrls.imageSmall} className="preview-image" />
                 </a>
                 }
                 { videoEntry.imageUrls.imageFaces && videoEntry.imageUrls.imageFaces.includes("microsoft") && 
                 <React.Fragment>
                    <br/>
                    <a href={videoEntry.imageUrls.imageFaces} target="_blank" rel="noreferrer">
                        <img alt="" src={videoEntry.imageUrls.imageFacesSmall} className="preview-image" />
                    </a>
                 </React.Fragment>
                 }
             </div>
            :''
        }
        </div>
  
};
export default Video;