import React, {useState, useEffect, useCallback} from "react";
import { database } from "../firebase";
import {Link, useParams} from 'react-router-dom';

const Manage = () => {
    const { id } = useParams();
    const [username, setUsername] = useState(id || "");
    const [secondUsername, setSecondUsername] = useState("");
    const [useCb, setUseCb] = useState(false);
    const [useC4, setUseC4] = useState(false);
    const [useSc, setUseSc] = useState(false);
    const [active, setActive] = useState("");
    const [message, setMessage] = useState("");

    useEffect(() => {
        database.ref('users/' + username).once('value',(snapshot) => {
            if (snapshot.exists()) {
                let userEntry = snapshot.val();
                if (userEntry.username) {
                    setSecondUsername(userEntry.username);
                }
                setActive(userEntry.active ? "active" : "deleted");
                setUseCb(userEntry.useCb || false);
                setUseC4(userEntry.useC4 || false);
                setUseSc(userEntry.useSc || false);
            } else {
                database.ref('users').once('value', (recordSnapshot) => {
                    const entry = recordSnapshot.val();
                    if (entry) {
                        for (const [key, value] of Object.entries(entry)) {
                            if (
                                (value.username && value.username.toLowerCase() === username.toLowerCase()) ||
                                value.userId.toLowerCase() === username.toLowerCase()
                            ) {
                                setUsername(value.userId || username);
                                setSecondUsername(value.username || "");
                                setActive(value.active ? "active" : "deleted");
                                setUseCb(value.useCb || false);
                                setUseC4(value.useC4 || false);
                                setUseSc(value.useSc || false);
                            }
                        }
                    }
                });
            }
        });
    }, [username]);

    const add = useCallback(async () => {
        let userRef = await database.ref('users/' + username);
        await userRef.once('value', async (snapshot) => {
            var rightNow = new Date();
            var res = rightNow.toISOString().slice(0, 10).replace(/-/g, "") + "235900";
            if (snapshot.exists()) {
                let userEntry = snapshot.val();
                userEntry.active = true;
                userEntry.username = secondUsername;
                userEntry.useCb = useCb;
                userEntry.useC4 = useC4;
                userEntry.useSc = useSc;
                await database.ref('users/' + username).set(userEntry);
                setMessage("Re-added " + username);
            } else {
                const userEntry = {
                    userId: username,
                    username: secondUsername,
                    lastRecording: res,
                    useCb: useCb,
                    useC4: useC4,
                    useSc: useSc,
                    active: true
                };
                await database.ref('users/' + username).set(userEntry);
                setMessage("Added " + username);
            }
        });
    }, [username, useCb, useC4, useSc, secondUsername]);

    const deleteUser = useCallback(async () => {
        let userRef = await database.ref('users/' + username);
        await userRef.on('value', async (snapshot) => {
            if (snapshot.exists()) {
                let userEntry = snapshot.val();
                userEntry.active = false;
                await database.ref('users/' + username).set(userEntry);
                setMessage("Deleted " + username);
            } else {
                setMessage("Not found " + username);
            }
        });
    }, [username]);

    const handleChange = (event) => {
        setUsername(event.target.value);
    };

    const handleSecondChange = (event) => {
        setSecondUsername(event.target.value);
    };

    const handleCheckboxChange = (setter) => (event) => {
        setter(event.target.checked);
    };

    const addUser = (event) => {
        add();
    };

    return (
        <div className="container mt-5">
            <div className="row mb-4">
                <div className="col-12">
                    <Link to="/all" className="btn btn-light">Back</Link>
                </div>
            </div>
            {message && (
                <div className="alert alert-info">
                    {message}
                </div>
            )}
            <div className="row mb-3">
                <div className="col-md-6">
                    <label htmlFor="usernameInput">Username</label>
                    <input
                        type="text"
                        id="usernameInput"
                        value={username}
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
                <div className="col-md-6">
                    <label htmlFor="secondUsernameInput">Second Username</label>
                    <input
                        type="text"
                        id="secondUsernameInput"
                        value={secondUsername}
                        onChange={handleSecondChange}
                        className="form-control"
                    />
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-md-4">
                    <div className="form-check">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="useCbCheckbox"
                            checked={useCb}
                            onChange={handleCheckboxChange(setUseCb)}
                        />
                        <label className="form-check-label" htmlFor="useCbCheckbox">CB</label>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-check">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="useC4Checkbox"
                            checked={useC4}
                            onChange={handleCheckboxChange(setUseC4)}
                        />
                        <label className="form-check-label" htmlFor="useC4Checkbox">C4</label>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-check">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="useScCheckbox"
                            checked={useSc}
                            onChange={handleCheckboxChange(setUseSc)}
                        />
                        <label className="form-check-label" htmlFor="useScCheckbox">SC</label>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <button onClick={addUser} className="btn btn-secondary mgr-10 mr-2">Add/Update</button>
                    <button onClick={deleteUser} className="btn btn-secondary mgr-10">Delete</button>
                </div>
            </div>


            {active && (
                <div className="row mb-3">
                    <div className="col-12">
                        <div className="alert alert-secondary">
                            {active === "active" ? "User is active" : "User is deleted"}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Manage;
